export function Coffee() {
  return (
    <a  class='coffee_widget' href="https://www.buymeacoffee.com/joerickard">
      <img
        alt="Buy me a Coffee"
        src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=joerickard&button_colour=40DCA5&font_colour=ffffff&font_family=Cookie&outline_colour=000000&coffee_colour=FFDD00"
      />
    </a>
  );
}

export default Coffee;